import React, { Component, ReactNode, ContextType } from 'react';
// atoms
import { Text } from '../../components/atoms/Text/Text';
import { Button } from '../../components/atoms/Button/Button';
// context
import { LanguageContext } from '../../context/LanguageContext/LanguageContext';
// css
import classes from './Home.module.css';
// strings
import strings from './strings.json';

export class Home extends Component<unknown>{

    static contextType = LanguageContext;
    context!: ContextType<typeof LanguageContext>;

    public constructor(props: unknown) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    public componentDidMount(): void {
        
        document.title = strings[this.context.language]['title'];
    }

    private onClick(): void {

        const anchor = document.createElement('a');
        anchor.href = `https://gitlab.com/gabrielmn/curriculum-vitae/-/raw/main/gabrielmn_curriculum_vitae.${this.context.language}.pdf?ref_type=heads&inline=false`;
        anchor.target = '_blank';
        anchor.rel = 'noreferrer noopener';
        anchor.click();
    }

    public render(): ReactNode {
        return (
            <main className={classes.container}>
                <Text
                    heading={{ role: 'heading', aria_level: 1 }}
                    className={classes.username}>
                    {strings[this.context.language]['username']}
                </Text>
                <Text
                    heading={{ role: 'heading', aria_level: 2 }}
                    className={classes.role} >
                    {strings[this.context.language]['role']}
                </Text>
                <Button
                    className={classes.button}
                    onClick={this.onClick}>
                    {strings[this.context.language]['button']}
                </Button>
            </main>
        );
    }
}