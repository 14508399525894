import React, { Component, ReactNode, MouseEvent } from 'react';
// css
import classes from './Button.module.css';

export interface ButtonProps {
    // mandatory props
    children: ReactNode | ReactNode[];
    onClick: (event: MouseEvent<HTMLButtonElement>) => void;
    // optional props
    className?: string
}

export class Button extends Component<ButtonProps>{

    public constructor(props: ButtonProps | Readonly<ButtonProps>) {
        super(props);
    }

    public render(): ReactNode {
        return (
            <button
                onClick={this.props.onClick}
                className={[classes.button, this.props.className].join(' ')}
            >
                {this.props.children}
            </button>
        );
    }
}