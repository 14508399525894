import React, { Component, ReactNode, MouseEvent } from 'react';
import { createPortal } from 'react-dom';

import classes from './Modal.module.css';

export interface ModalProps {

    show: boolean;
    hide?: () => void;
    children: ReactNode | ReactNode[];
}

export class Modal extends Component<ModalProps>{

    public constructor(props: ModalProps | Readonly<ModalProps>) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

    }

    public componentDidUpdate(prevProps: Readonly<ModalProps>): void {
        
        // add event listener if modal becomes visible
        if(prevProps.show === false && this.props.show === true){
            window.addEventListener('keydown', this.onKeyDown, false);
        }
        // remove event listener if modal becomes invisible
        else if(prevProps.show === true && this.props.show === false){
            window.removeEventListener('keydown', this.onKeyDown, false);
        }
    }

    private onClick(event: MouseEvent<HTMLDivElement>){

        if(event.currentTarget === event.target && this.props.hide !== undefined){
            this.props.hide();
        }
    }

    private onKeyDown(event: globalThis.KeyboardEvent){

        if(event.code === 'Escape' && this.props.hide !== undefined){
            this.props.hide();
        }
        event.stopImmediatePropagation();  
    }


    public render(): ReactNode {

        if (this.props.show) {
            return (
                createPortal(
                    <div className={classes.modal} onClick={this.onClick}>
                        {this.props.children}
                    </div>,
                    document.body
                )
            );
        }

        return null;

    }
}