import React, { Component, ReactNode, ContextType } from 'react';
// organisms
import { LoadingCard } from '../../components/organisms/LoadingCard/LoadingCard';
// templates
import { Project, ProjectType } from '../../components/templates/Project/Project';
import { LanguageContext } from '../../context/LanguageContext/LanguageContext';
// css
import classes from './Projects.module.css';
// strings
import strings from './strings.json';

interface ProjectsState {
    loading: boolean;
    projects: ProjectType[];
}

export class Projects extends Component<unknown, ProjectsState> {

    static contextType = LanguageContext;
    context!: ContextType<typeof LanguageContext>;

    public constructor(props: unknown) {
        super(props);

        this.state = {
            loading: true,
            projects: []
        };
    }

    public async componentDidMount(): Promise<void> {

        // change page title
        document.title = strings[this.context.language]['title'];

        if (!process.env.REACT_APP_API_URL || !process.env.REACT_APP_API_KEY)
            throw new Error('Missing env variable');

        try {

            const response = await fetch(`${process.env.REACT_APP_API_URL}/projects`, {
                method: 'GET',
                headers: {
                    'Accept-Language': this.context.language,
                    'X-Functions-Key': process.env.REACT_APP_API_KEY,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });

            if (response.status !== 200)
                throw new Error(`HTTP status: ${response.status}\nHTTP message:${response.body}`);

            const body: ProjectType[] = await response.json();

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            this.setState((current) => {
                return {
                    loading: false,
                    projects: body
                };
            });

        } catch {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            this.setState((current) => {
                return {
                    loading: false
                };
            });
            return;
        }
    }

    public render(): ReactNode {
        return (
            <main className={classes.main} >
                <LoadingCard title={strings[this.context.language]['loading']} loading={this.state.loading}/>
                <ul className={classes.all_projects_list}>
                    {this.state.projects.map((item) => {
                        return (
                            <li key={item.id}>
                                <Project
                                    className={classes.all_projects_list_item}
                                    project={item} />
                            </li>
                        );
                    })}
                </ul>
            </main>
        );
    }
}