import React, { Component, ReactNode } from 'react';

export interface Language {
    language: 'en-us' | 'pt-br'
}

export const LanguageContext = React.createContext<Language>({} as Language);

export interface LanguageProviderProps {
    children: ReactNode | ReactNode[]
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LanguageProviderState extends Language {

}

export class LanguageProvider extends Component<LanguageProviderProps, LanguageProviderState>{

    public constructor(props: LanguageProviderProps | Readonly<LanguageProviderProps>) {
        super(props);

        const browserLanguage = navigator.language.toLowerCase();
        // if the language is not supported, change to the default language
        if (browserLanguage !== 'en-us' && browserLanguage !== 'pt-br'){
            document.documentElement.lang = 'en-us';
            this.state = { 
                language: 'en-us'
            };
        }else{
            document.documentElement.lang = browserLanguage;
            this.state = {
                language: browserLanguage
            };
        }        
    }

    public render(): ReactNode {

        const { language } = this.state;
        return (
            <LanguageContext.Provider value={{
                language
            }}>
                {this.props.children}
            </LanguageContext.Provider>
        );
    }
}

export const LanguageConsumer = LanguageContext.Consumer;