import React, { Component, ReactNode, ContextType } from 'react';
// organismos
import { LoadingCard } from '../../components/organisms/LoadingCard/LoadingCard';
// templates
import { Certificate, CertificateType } from '../../components/templates/Certificate/Certificate';
// context
import { LanguageContext } from '../../context/LanguageContext/LanguageContext';
// css
import classes from './Certificates.module.css';
// strings
import strings from './strings.json';

interface CertificateState {
    loading: boolean;
    certificates: CertificateType[];
}

export class Certificates extends Component<unknown, CertificateState>{

    static contextType = LanguageContext;
    context!: ContextType<typeof LanguageContext>;

    public constructor(props: unknown) {
        super(props);

        this.state = {
            loading: true,
            certificates: []
        };
    }

    public async componentDidMount(): Promise<void> {

        document.title = strings[this.context.language]['title'];

        if (!process.env.REACT_APP_API_URL || !process.env.REACT_APP_API_KEY)
            throw new Error('Missing env variable');

        try {

            const response = await fetch(`${process.env.REACT_APP_API_URL}/certificates`, {
                method: 'GET',
                headers: {
                    'Accept-Language': this.context.language,
                    'X-Functions-Key': process.env.REACT_APP_API_KEY,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });

            if (response.status !== 200)
                throw new Error(`HTTP status: ${response.status}\nHTTP message:${response.body}`);

            const body: CertificateType[] = await response.json();
            const sorted: CertificateType[] = body.sort((a, b) => {

                const dateA = Date.parse(a.conclusionDate);
                const dateB = Date.parse(b.conclusionDate);

                if (dateA < dateB) {
                    return 1;
                }
                return -1;
            });

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            this.setState((current) => {
                return {
                    loading: false,
                    certificates: sorted
                };
            });

        } catch {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            this.setState((current) => {
                return {
                    loading: false
                };
            });
            return;
        }

    }

    public render(): ReactNode {
        return (
            <main className={classes.main}>
                <LoadingCard title={strings[this.context.language]['loading']} loading={this.state.loading} />
                <ul
                    className={classes.list}>
                    {this.state.certificates.map((item) => {
                        return (
                            <Certificate
                                key={item.id}
                                certificate={item} />
                        );
                    })}
                </ul>
            </main>
        );
    }
}