import React, { Component, ReactNode, ContextType } from 'react';
// atoms
import { Text } from '../../atoms/Text/Text';
import { Image } from '../../atoms/Image/Image';
import { Button } from '../../atoms/Button/Button';
// types
import { ProjectType } from '../Project/Project';
// assets
import { ReactComponent as CloseIcon } from '../../../assets/icons/close_48dp.svg';
// context
import { LanguageContext } from '../../../context/LanguageContext/LanguageContext';

import classes from './ProjectDetails.module.css';

export interface ProjectDetailsProps {
    project: ProjectType;
    onClickClose: () => void;
}

interface ProjectDetailsState{
    description: string
}

export class ProjectDetails extends Component<ProjectDetailsProps, ProjectDetailsState>{

    static contextType = LanguageContext;
    context!: ContextType<typeof LanguageContext>;

    public constructor(props: ProjectDetailsProps | Readonly<ProjectDetailsProps>) {
        super(props);

        this.onClickRepository = this.onClickRepository.bind(this);

        this.state = {
            description: this.props.project.description
        };
    }

    private onClickRepository() {
        window.open(this.props.project.repository, '_newtab');
    }

    public render(): ReactNode {


        return (
            <div className={classes.card}>

                <CloseIcon className={classes.close_icon} onClick={this.props.onClickClose} />

                <div className={classes.header}>
                    <Image className={classes.header_image} source={this.props.project.image} alt={''} />
                    <div className={classes.header_info}>

                        <Text className={classes.name}>
                            {this.props.project.name}
                        </Text>

                        <ul className={classes.header_tags}>
                            {this.props.project.topics.map((item) => {
                                return (
                                    <li key={item.id}>
                                        <Text className={classes.header_tag}>
                                            {item.topic}
                                        </Text>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>

                </div>


                <Text className={classes.description}>
                    {this.state.description}
                </Text>

                {(this.props.project.repository !== undefined) &&
                    <div className={classes.links}>

                        {this.props.project.repository !== undefined &&
                            <Button
                                className={classes.link}
                                onClick={this.onClickRepository}>
                                Repository
                            </Button>
                        }
                    </div>
                }

            </div>
        );
    }
}