import React, { Component, ReactNode, Fragment, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Header } from './components/organisms/Header/Header';

import { Home } from './pages/Home/Home';
import { Experiences } from './pages/Experiences/Experiences';
import { Projects } from './pages/Projects/Projects';
import { Certificates } from './pages/Certificates/Certificates';
import { Contact } from './pages/Contact/Contact';
import { NotFound } from './pages/NotFound/NotFound';

// pages
// const Home = lazy(() =>
//     import('./pages/Home/Home').then((module) => ({ default: module.Home }))
// );

// const Experiences = lazy(() =>
//     import('./pages/Experiences/Experiences').then((module) => ({ default: module.Experiences }))
// );

// const Projects = lazy(() =>
//     import('./pages/Projects/Projects').then((module) => ({ default: module.Projects }))
// );

// const Certificates = lazy(() =>
//     import('./pages/Certificates/Certificates').then((module) => ({ default: module.Certificates }))
// );

// const Contact = lazy(() =>
//     import('./pages/Contact/Contact').then((module) => ({ default: module.Contact }))
// );

// const NotFound = lazy(() =>
//     import('./pages/NotFound/NotFound').then((module) => ({ default: module.NotFound }))
// );

export class App extends Component {

    public render(): ReactNode {
        return (
            <Fragment>
                <Header />
                <Suspense>
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/experience' element={<Experiences />} />
                        <Route path='/projects' element={<Projects />} />
                        <Route path='/certificates' element={<Certificates />} />
                        <Route path='/contact' element={<Contact />} />
                        <Route path='*' element={<NotFound />} />
                    </Routes>
                </Suspense>
            </Fragment>
        );
    }
}
