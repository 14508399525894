import React, { Component, ReactNode, ContextType } from 'react';
// atoms
import { Text } from '../../components/atoms/Text/Text';
import { Link } from '../../components/atoms/Link/Link';
// context
import { LanguageContext } from '../../context/LanguageContext/LanguageContext';

import strings from './strings.json';
import classes from './Contact.module.css';

export class Contact extends Component<unknown>{

    static contextType = LanguageContext;
    context!: ContextType<typeof LanguageContext>;

    public constructor(props: unknown) {
        super(props);
    }

    public componentDidMount(): void {
        
        document.title = strings[this.context.language]['title'];
    }

    public render(): ReactNode {
        return (
            <main
                className={classes.main}>
                <Text
                    className={classes.contact_instructions}>
                    {strings[this.context.language]['contact-instructions']}
                    <Link
                        className={classes.email}
                        href='mailto:gabriel.m.nori@hotmail.com' >
                        gabriel.m.nori@hotmail.com
                    </Link>
                </Text>
            </main>
        );
    }
}