import React, { Component, ReactNode, ContextType } from 'react';
// atoms
import { Text } from '../../atoms/Text/Text';
import { Image, ImageType } from '../../atoms/Image/Image';
// context
import { LanguageContext } from '../../../context/LanguageContext/LanguageContext';
// others
import classes from './Experience.module.css';
import strings from './strings.json';

export type Responsibility = {
    id: string;
    responsibility: string;
};

export type ExperienceType = {
    id: string;
    type: string;
    company: string;
    position: string;
    startDate: string;
    endDate: string;
    image: ImageType;
    responsibilities: Responsibility[];
}

export interface ExperienceProps {
    experience: ExperienceType;
}

export class Experience extends Component<ExperienceProps>{

    static contextType = LanguageContext;
    context!: ContextType<typeof LanguageContext>;

    public constructor(props: ExperienceProps | Readonly<ExperienceProps>) {
        super(props);
    }

    public render(): ReactNode {

        return (
            <li className={classes.container}>

                <Image
                    className={classes.image}
                    source={this.props.experience.image}
                    alt={`${strings[this.context.language]['company']} ${this.props.experience.company} logo`} />

                <div className={classes.company}>
                    <Text
                        className={classes.company_name}>
                        {this.props.experience.company}
                    </Text>
                    <Text
                        className={classes.position_title}>
                        {`${this.props.experience.position} - ${this.props.experience.type}`}
                    </Text>
                </div>

                <div className={classes.date}>
                    <Text
                        className={classes.date_text}>
                        {strings[this.context.language]['start']}{this.props.experience.startDate}
                    </Text>
                    <Text
                        className={classes.date_text}>
                        {strings[this.context.language]['end']}{(this.props.experience.endDate) ? this.props.experience.endDate : strings[this.context.language]['ongoing']}
                    </Text>
                </div>

                <ul className={classes.list}>
                    {this.props.experience.responsibilities.map((item) => {
                        return (
                            <li key={item.id} className={classes.list_item}>
                                <Text className={classes.list_item_text}>
                                    {item.responsibility}
                                </Text>
                            </li>
                        );
                    })}
                </ul>
            </li>
        );
    }
}