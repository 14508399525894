import React, { Component, ReactNode } from 'react';

import { Text } from '../../atoms/Text/Text';
import { Link } from '../../atoms/Link/Link';

import classes from './Certificate.module.css';

export type CertificateTopicType = {
    id: string;
    topic: string;
}

export type CertificateType = {
    id: string;
    name: string;
    conclusionDate: string;
    topics: CertificateTopicType[];
    link: string;
}

export interface CertificateProps {
    certificate: CertificateType;

    className?: string;
}

export class Certificate extends Component<CertificateProps>{

    public constructor(props: CertificateProps | Readonly<CertificateProps>) {
        super(props);
    }

    public render(): ReactNode {

        return (
            <li className={[classes.container, this.props.className].join(' ')}
            >
                <div className={classes.header}>
                    <Text
                        className={classes.header_title}>
                        {this.props.certificate.name}
                    </Text>

                    <Text
                        className={classes.header_date}>
                        {this.props.certificate.conclusionDate}
                    </Text>

                </div>

                <ul className={classes.list}>
                    {this.props.certificate.topics.map((item, index) => {
                        return (
                            <li key={index}>
                                <Text
                                    className={classes.list_item}>
                                    {item.topic}
                                </Text>
                            </li>
                        );
                    })}
                </ul>

                <Text
                    className={classes.content_text} >
                    Link: <Link className={classes.link} 
                        href={this.props.certificate.link} >
                        {this.props.certificate.link}
                    </Link>

                </Text>
            </li>
        );
    }
}