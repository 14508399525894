import React, { Component, ReactNode, ContextType } from 'react';
import { NavLink } from 'react-router-dom';
// context
import { LanguageContext } from '../../../context/LanguageContext/LanguageContext';
// icons
import { ReactComponent as MenuIcon } from '../../../assets/icons/menu_48dp.svg';
// others
import classes from './Header.module.css';
import strings from './strings.json';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface HeaderProps {
}

interface HeaderState {
    open: boolean;
}

export class Header extends Component<HeaderProps, HeaderState>{

    static contextType = LanguageContext;
    context!: ContextType<typeof LanguageContext>;


    public constructor(props: HeaderProps | Readonly<HeaderProps>) {
        super(props);

        this.state = {
            open: false
        };

        this.onClick = this.onClick.bind(this);
        this.onNavigateToLink = this.onNavigateToLink.bind(this);
    }

    private onClick(): void {
        this.setState((current) => {
            return {
                open: !current.open
            };
        });
    }

    private onNavigateToLink(): void {
        this.setState({ open: false });
    }

    public render(): ReactNode {

        return (
            <header className={classes.header}>

                <nav className={classes.nav}>

                    <div className={classes.mobile_header}>
                        <NavLink
                            onClick={this.onNavigateToLink}
                            to={'/'}
                            className={({ isActive }) => isActive ? classes.header_link_active : classes.header_link}
                        >
                            {strings[this.context.language]['brand']}
                        </NavLink>

                        <MenuIcon
                            className={classes.menu_icon}
                            onClick={this.onClick} />
                    </div>


                    <ul className={[classes.header_list, (this.state.open ? classes.header_list_open : ' ')].join(' ')}>

                        <li className={classes.header_list_item}>
                            <NavLink
                                onClick={this.onNavigateToLink}
                                to={'/experience'}
                                className={({ isActive }) => isActive ? classes.header_link_active : classes.header_link}
                            >
                                {strings[this.context.language]['experience']}
                            </NavLink>
                        </li>

                        <li className={classes.header_list_item}>
                            <NavLink
                                onClick={this.onNavigateToLink}
                                to={'/projects'}
                                className={({ isActive }) => isActive ? classes.header_link_active : classes.header_link}
                            >
                                {strings[this.context.language]['projects']}
                            </NavLink>
                        </li>

                        <li className={classes.header_list_item}>
                            <NavLink
                                onClick={this.onNavigateToLink}
                                to={'/certificates'}
                                className={({ isActive }) => isActive ? classes.header_link_active : classes.header_link}
                            >
                                {strings[this.context.language]['certificates']}
                            </NavLink>
                        </li>

                        <li className={classes.header_list_item}>
                            <NavLink
                                onClick={this.onNavigateToLink}
                                to={'/contact'}
                                className={({ isActive }) => isActive ? classes.header_link_active : classes.header_link}
                            >
                                {strings[this.context.language]['contact']}
                            </NavLink>
                        </li>

                    </ul>
                </nav>
            </header>
        );
    }
}