import React, { Component, ReactNode } from 'react';
// atoms
import { Modal } from '../../atoms/Modal/Modal';
import { Text } from '../../atoms/Text/Text';
// css
import classes from './LoadingCard.module.css';

export interface LoadingCardProps {

    title: string;
    loading: boolean;
    className?: string;

}

export class LoadingCard extends Component<LoadingCardProps>{

    public constructor(props: LoadingCardProps | Readonly<LoadingCardProps>) {
        super(props);
    }

    public render(): ReactNode {

        return (
            <Modal show={this.props.loading}>
                <div className={[classes.card, this.props.className].join(' ')}>
                    <Text className={classes.text}>
                        {this.props.title}
                    </Text>
                    <div className={classes.spinner} />
                </div>
            </Modal>
        );
    }
}