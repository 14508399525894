import React, { Component, ReactNode, ContextType } from 'react';
// organismos
import { LoadingCard } from '../../components/organisms/LoadingCard/LoadingCard';
// templates
import { Experience, ExperienceType } from '../../components/templates/Experience/Experience';
// context
import { LanguageContext } from '../../context/LanguageContext/LanguageContext';
// css
import classes from './Experiences.module.css';
// strings
import strings from './strings.json';

export interface ExperiencesState {
    loading: boolean;
    experiences: ExperienceType[];
}

export class Experiences extends Component<unknown, ExperiencesState>{

    static contextType = LanguageContext;
    context!: ContextType<typeof LanguageContext>;

    public constructor(props: unknown) {
        super(props);

        this.state = {
            loading: true,
            experiences: []
        };
    }

    public async componentDidMount(): Promise<void> {

        document.title = strings[this.context.language]['title'];

        if (!process.env.REACT_APP_API_URL || !process.env.REACT_APP_API_KEY)
            throw new Error('Missing env variable');

        try {

            const response = await fetch(`${process.env.REACT_APP_API_URL}/experiences`, {
                method: 'GET',
                headers: {
                    'Accept-Language': this.context.language,
                    'X-Functions-Key': process.env.REACT_APP_API_KEY,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });

            if (response.status !== 200)
                throw new Error(`HTTP status: ${response.status}\nHTTP message:${response.body}`);

            const body: ExperienceType[] = await response.json();
            const sorted: ExperienceType[] = body.sort((a, b) => {

                const dateA = Date.parse(a.startDate);
                const dateB = Date.parse(b.startDate);

                if (dateA < dateB) {
                    return 1;
                }
                return -1;
            });

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            this.setState((current) => {
                return {
                    loading: false,
                    experiences: sorted
                };
            });

        } catch {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            this.setState((current) => {
                return {
                    loading: false
                };
            });
            return;
        }
    }

    public render(): ReactNode {
        return (
            <main className={classes.main}>
                <LoadingCard title={strings[this.context.language]['loading']} loading={this.state.loading} />
                <ul className={classes.list}>
                    {this.state.experiences.map((item) => {

                        return (
                            <Experience key={item.id} experience={item} />
                        );
                    })}
                </ul>
            </main>
        );
    }
}