import React, { Component, ReactNode} from 'react';
import classes from './Link.module.css';

export interface LinkProps {

    href: string;
    children: ReactNode | ReactNode[];

    className?: string;
}

export class Link extends Component<LinkProps>{

    public constructor(props: LinkProps | Readonly<LinkProps>){
        super(props);
    }

    public render(): ReactNode {
        return(
            <a
                className={[classes.link, this.props.className].join(' ')}
                tabIndex={0}
                href={this.props.href}>
                {this.props.children}
            </a>
        );
    }
}