import React, { Component, ReactNode, SyntheticEvent, ContextType } from 'react';
// context
import { LanguageContext } from '../../../context/LanguageContext/LanguageContext';
// assets
import { ReactComponent as MissingImageIcon } from '../../../assets/icons/missing_image.svg';
// css
import classes from './Image.module.css';
// strings
import strings from './strings.json';

export type ImageType = string;

export interface ImageProps {
    // mandatory
    source: ImageType
    alt: string;
    // optional
    className?: string;
}

interface ImageState {
    missing: boolean;
}

export class Image extends Component<ImageProps, ImageState>{

    static contextType = LanguageContext;
    context!: ContextType<typeof LanguageContext>;

    public constructor(props: ImageProps | Readonly<ImageProps>) {
        super(props);

        this.state = {
            missing: false
        };

        this.onError = this.onError.bind(this);
    }

    public componentDidUpdate(prevProps: ImageProps): void {
        if (prevProps.source !== this.props.source)
            this.setState({ missing: false });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private onError(event: SyntheticEvent<HTMLImageElement>): void {

        this.setState({ missing: true });
    }

    public render(): ReactNode {


        if (this.state.missing)
            return (
                <MissingImageIcon
                    title={strings[this.context.language]['missing']}
                    className={[classes.image, this.props.className].join(' ')} />
            );


        return (
            <img
                alt={this.props.alt}
                src={this.props.source}
                className={[classes.image, this.props.className].join(' ')}
                onError={this.onError} />
        );

    }
}