import React, { Component, ReactNode } from 'react';
// css
import classes from './Text.module.css';

export type TextHeadingType = {
    role: 'heading';
    aria_level: 1 | 2 | 3 | 4 | 5 | 6;
}
export interface TextProps {
    
    children: ReactNode | ReactNode[];
    heading?: TextHeadingType;
    className?: string;    
}
export class Text extends Component<TextProps>{

    public constructor(props: TextProps | Readonly<TextProps>){
        super(props);
    }

    public render(): ReactNode {
        
        return(
            <span
                role={this.props.heading?.role}
                aria-level={this.props.heading?.aria_level} 
                className={[classes.text, this.props.className].join(' ')}>
                {this.props.children}
            </span>
        );

    }
}