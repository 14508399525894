import React, { Component, ReactNode, ContextType, Fragment } from 'react';
// atoms
import { Image, ImageType } from '../../atoms/Image/Image';
import { Text } from '../../atoms/Text/Text';
import { Modal } from '../../atoms/Modal/Modal';
// templates
import { ProjectDetails } from '../ProjectDetails/ProjectDetails';
// context
import { LanguageContext } from '../../../context/LanguageContext/LanguageContext';
// css
import classes from './Project.module.css';
// strings
import strings from './strings.json';

export type ProjectTopicType = {
    id: string;
    topic: string;
}

export type ProjectType = {
    id: string;
    image: ImageType;
    name: string;
    topics: ProjectTopicType[];
    description: string;
    repository?: string;
    images: string[];
}

export interface ProjectProps {

    project: ProjectType;
    className?: string;

}

interface ProjectState {

    showDetails: boolean;
}
export class Project extends Component<ProjectProps, ProjectState>{

    static contextType = LanguageContext;
    context!: ContextType<typeof LanguageContext>;

    public constructor(props: ProjectProps | Readonly<ProjectProps>) {
        super(props);

        this.state = {
            showDetails: false
        };

        this.onClick = this.onClick.bind(this);
        this.onClickClose = this.onClickClose.bind(this);
    }

    private onClick() {
        this.setState({ showDetails: true });
    }

    private onClickClose() {
        this.setState({ showDetails: false });
    }

    public render(): ReactNode {

        const imageAlt = `${strings[this.context.language]['project']} ${this.props.project.name} ${strings[this.context.language]['image']}`;

        return (
            // TODO find way to remove Fragment from here
            //  reason why is here: without Fragment here the modal will not work properly, it will be possible to click on elements behind the modal
            <Fragment>
                <Modal show={this.state.showDetails} hide={this.onClickClose}>
                    <ProjectDetails onClickClose={this.onClickClose} project={this.props.project} />
                </Modal>

                <div
                    className={[classes.card, this.props.className].join(' ')}
                    onClick={this.onClick}>
                    <Image
                        className={classes.image}
                        source={this.props.project.image}
                        alt={imageAlt} />
                    <div className={classes.card_header}>
                        <Text className={classes.name}>
                            {this.props.project.name}
                        </Text>
                        <ul className={classes.tags}>
                            {this.props.project.topics.map((item) => {
                                return (
                                    <li key={item.id}>
                                        <Text className={classes.tag}>
                                            {item.topic}
                                        </Text>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </Fragment>
        );
    }
}