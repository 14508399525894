import React, { Component, ReactNode, ContextType } from 'react';
// atoms
import { Text } from '../../components/atoms/Text/Text';
// context
import { LanguageContext } from '../../context/LanguageContext/LanguageContext';
// css
import classes from './NotFound.module.css';
// strings
import strings from './strings.json';

export class NotFound extends Component<unknown> {

    static contextType = LanguageContext;
    context!: ContextType<typeof LanguageContext>;

    public constructor(props: unknown){
        super(props);
    }

    public componentDidMount(): void {
        
        document.title = strings[this.context.language]['title'];
    }

    public render(): ReactNode {

        return (
            <main className={classes.main}>
                <Text className={classes.page_not_found}>
                    {strings[this.context.language]['page-not-found']}
                </Text>
                <Text className={classes.instructions}>
                    {strings[this.context.language]['instructions']}
                </Text>
            </main>
        );
    }

}